<template>
  <div class="relative flex items-center appearance-none w-full pb-4">
    <input :id="id" class="hidden" :name="name" :accept="accept" type="file" @change="handleFileChange" />
    <label
      v-if="innerText && !isImageFile"
      class="flex items-center justify-center gap-5 select-none p-8 rounded-2xl cursor-pointer w-full"
      :class="{
        'dashed-border-dark text-primary-700': dark && !errorMessage,
        'dashed-border text-primary-700': !dark && !errorMessage,
        'dashed-border-red text-red-700': errorMessage,
      }"
      :for="id"
    >
      <svg-icon v-if="icon" :name="icon" class="w-5 h-5" />
      <span>{{ fieldValue ? fieldValue.name : innerText }}</span>
    </label>
    <label
      v-if="label && !innerText && !isImageFile"
      class="block z-10 absolute [ -top-6 left-0 ] select-none"
      :class="{
        'text-primary-400 ': dark && !errorMessage,
        'text-secondary-400': !dark && !errorMessage,
        'text-red-700': errorMessage,
      }"
      :for="id"
    >
      <span>{{ label }}</span>
      <span>{{ required ? ' *' : '' }}</span>
    </label>
    <span v-if="errorMessage" class="-bottom-2 absolute text-red-700 text-sm">{{ errorMessage }}</span>
    <div v-if="fieldValue && isImageFile" class="w-full flex items-center justify-between">
      <img :src="imageSrc" alt="" class="h-30 w-30 object-cover rounded-2xl" />

      <div class="flex items-center gap-x-10">
        <button class="text-primary-700 underline text-sm flex items-center gap-x-4" type="button" @click="showImage">
          <svg-icon name="view-image" class="w-6 h-6" />
          {{ $t('viewImage') }}
        </button>
        <label type="button" class="text-primary-700 underline text-sm flex items-center gap-x-4" :for="id">
          <svg-icon name="reupload" class="w-6 h-6" />

          {{ $t('reupload') }}
        </label>
      </div>
    </div>
  </div>

  <dialog ref="imageDialogRef">
    <button class="absolute top-2 right-2" type="button" @click="hideImage">
      <svg-icon name="close" class="w-6 h-6" />
    </button>
    <AppImage :src="imageSrc" alt="" class="h-96 w-full object-cover" />
  </dialog>
</template>

<script setup lang="ts">
import AppImage from './global/AppImage.vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  innerText: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: 'arrow-upload',
  },
  rules: {
    type: [String, Object],
    default: '',
  },
  dark: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  accept: {
    type: String,
    required: true,
  },
});

const nameRef = toRef(props, 'name');

const imageDialogRef = ref<HTMLDialogElement | null>(null);
const { error } = useAlerts();
const { errorMessage, value: fieldValue } = useField<File>(nameRef.value, {
  __type: 'file',
});

const handleFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const file = target.files?.[0];
  if (!file) {
    error('Please select a file');
    return;
  }
  fieldValue.value = file;
};

const isImageFile = computed(() => {
  return fieldValue.value?.type.startsWith('image');
});
const imageSrc = ref('');

const { t: $t } = useI18n({
  useScope: 'local',
});

watch(fieldValue, file => {
  if (!file) {
    return;
  }
  const reader = new FileReader();
  reader.onload = e => {
    imageSrc.value = e.target?.result as string;
  };
  reader.readAsDataURL(file);
});

const showImage = () => {
  imageDialogRef.value?.showModal();
};

const hideImage = () => {
  imageDialogRef.value?.close();
};
</script>

<style lang="postcss" scoped>
.dashed-border {
  /**  
  * A generated custom dashed border background 
  * @see https://kovart.github.io/dashed-border-generator/
  */
  background-image: url('~/assets/icons/dashed-border.svg');
}

.dashed-border-dark {
  background-image: url('~/assets/icons/dashed-border-dark.svg');
}

.dashed-border-red {
  background-image: url('~/assets/icons/dashed-border-error.svg');
}
</style>
<i18n>
{
  "en": {
    "viewImage": "View Image",
    "reupload": "Reupload",
    "findDescription": "Find the file you want to upload"
  },
  "ar": {
    "viewImage": "عرض الصورة",
    "reupload": "إعادة تحميل",
    "findDescription": "ابحث عن الملف الذي تريد تحميله"
  }
}
</i18n>
